<template>
    <div class="home">
        <div class="top flex">
            <img class="logo" @click="goHome('')" src="@/assets/home2/logo@2x.png" alt="">
            <div class="flex">
                <div class="nav" @click="goHome('#about')">关于赛事</div>
                <div class="nav" @click="goHome('#format')">赛事赛制</div>
                <div class="nav"  @click="goHome('#review')">评审介绍</div>
                <div class="nav" @click="goSign">赛事报名</div>
            </div>
        </div>
        <!-- <div class="crumbs">首页 > 赛事赛道 > <span style="color: #45B79C">赛道五</span></div> -->
        <div class="crumbs"><span @click="$router.push('/saidao1')">赛道一</span> | <span  @click="$router.push('/saidao2')">赛道二</span> | <span @click="$router.push('/saidao3')" >赛道三</span> | <span  @click="$router.push('/saidao4')">赛道四</span> | <span style="color: #45B79C">赛道五</span></div>

        <div class="banner">
            <div class="title">赛道五</div>
            <div class="desc">“无界常熟”“文创物品”创新设计开放命题</div>
        </div>
        <div class="content flex-vc" >
            <div class="left">
                <div class="text1">天马行空，任君驰骋</div>
                <div class="text2">只要与常熟文化创意、文旅资源相关，所有的创作内容我们来者不拒 <br>这是给到参赛者最大自由度的设计命题，创作应充分考虑以常熟地区传统文化、文旅景区、民俗非遗、地方美食等城市文化符号为创意题材进行设计。提倡设计风格年轻化，作品表现手法不限。<br> 平面设计——不限于海报、（书籍/画册等）出版物、字库字体、创意字形、视觉实验等 <br>插画艺术——不限于商业插画、绘本、影视概念设定、游戏概念设定等<br> 数字影视——不限于数字短片 、故事动画、Motion Graphic、影视短片等 摄影作品——不限于建筑摄影、人文/纪实摄影、时尚艺术摄影、人像/风光/美食摄影等</div>
            </div>
            <div class="right">
                <div class="text1">参赛者根据以下要求进行设计</div>
                <div class="text3">
①平面/插画/包装/摄影等 请将设计图、效果图、照片、介绍文字等整合后以方案形式进行呈现，文件大小不大于30M，文件格式为PDF格式 <br>②数字影视 每件作品需提交不超过3条视频文档，以及体现作品的预览效果关键图电子档， 视频文档要求：尺寸：1080p，存储格式：avi、mp4、wmv、flv等。 <br>电子档文件大小不大于30M，文件格式为PDF格式
                </div>
            </div>
        </div>
        <!-- footer -->
        <div id="sign" class="footer">
        <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
        <div class="text-wrap flex-vcbetween">
            <div>
            <div><span>指导单位</span>中共常熟市委宣传部</div>
            <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
            <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
            </div>
            <div class="flex-vc">
            <div><span>官方热线:</span></div>
            <div>
                <div>邮箱: 962991363@qq.com</div>
                <div>微信: 13817518262</div>
            </div>
            </div>
        </div>
        <div class="text-bottom flex-between">
            <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="font-weight: bold">changshustory.</span> ALL Right Reserved <a style="color:#666" href="https://beian.miit.gov.cn/">沪ICP备12038716号-10</a></div>
            <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        goSign() {
            this.$router.push('/sign')
        },
        goHome(id){
            this.$router.push({name:'Home',query: {id: id}})
        },
    }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
  margin: 0 auto;
  width: 1920px;
  .top {
    background: #FFFFFF;
    height: 134px;
    border-bottom: 1px solid #EBEBEB;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .nav:hover {
      color:#45B79C;
      font-weight: bold;
      cursor: pointer
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
    .crumbs {
        width: 1200px;
        line-height: 76px;
        font-size: 20px;
        color: #231815;
        text-align: left;
        margin: 0 auto;
        span {
          cursor: pointer;
        }
    }
    .banner {
        width: 1200px;
        height: 126px;
        background: url('~@/assets/saidao/bg@2x.png');
        background-size: 100% 100%;
        margin: 0 auto;
        color: #FFFFFF;
        padding-top: 20px;
        .title {
            font-size: 44px;
            font-weight: bold;
        }
        .desc {
            margin-top: 10px;
            font-size: 24px;
        }

    }
    .content {
        height: 554px;
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        line-height: 36px;
        .left {
            width: 600px;
        }
        .right {
            width: 540px;
            padding-left: 60px;
        }
        .text1 {
            font-size: 20px;
            color: #231815;
            font-weight: bold;
            margin-top: 32px;
        }
        .text2 {
            margin-top: 14px;
            font-size: 18px;
            color: #231815;
            width: 546px;
            height: 440px;
            overflow-y: scroll;
        }
        .text3 {
            width: 506px;
            height: 406px;
            border: 1px solid #E5E5E5;
            overflow-y: scroll;
            margin-top: 14px;
            padding: 17px;
            font-size: 18px;
            color: #231815;
        }
    }
      .footer {
    width: 1920px;
    height: 170px;
    background: #C1C1C1;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #666666;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-weight: bold;
        font-size: 15px;
        // color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #666;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #666666;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }
  }
}
</style>


